import React, { useRef, useState } from 'react';
import StandardLayout from '../layouts/standard';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { backendHost } from '../../config';

export default function CareersPage(props) {

    const [dragOver, setDragOver] = useState(false);
    const [disableForm, setDisableForm] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const submitForm = data => {
        const formData = new FormData();
        formData.append('files', data.cv[0]);
        axios.post(backendHost + '/upload', formData)
            .then(res => sendData(res))
            .catch(err => {
                alert('An error occurred')
                setDisableForm(false);
            })
        
        const sendData = res => {
            axios.post(backendHost + '/careers', { ...data, cv: res.data[0] })
                .then(success => {
                    alert('Successfully submitted!')
                    setDisableForm(false);
                })
                .catch(err => {
                    alert('An error occured')
                    setDisableForm(false);
                })
        }
        setDisableForm(true);
    }

    const errorMessage = msg => <span className="color-primary">{msg}</span>
    const requiredMessage = errorMessage('This field is required');

    return <StandardLayout title="Careers">
        <section className="uk-section">
            <div className="uk-container">
                <div className="pv6 w-100 flex items-center justify-center">
                    <form className="w-100" onSubmit={handleSubmit(submitForm)}>
                        <div className="uk-card uk-card-default pa4 bg-white flex w-100">
                            <div className="w-50 ph3" >
                                <div style={{
                                    border: '2px solid #999',
                                    borderStyle: 'dashed',
                                    background: dragOver ? '#ddd' : '#fff'
                                }}
                                className="flex flex-column items-center h-100 justify-center"
                                onDrop={e => {
                                    console.log(e)
                                    setDragOver(false);
                                }}
                                onDragOver={e => {
                                    e.preventDefault()
                                    setDragOver(true);
                                }}>
                                    <h3 className="mb0">Drop your CV here</h3>
                                    <h2 className="mt2 mb3">or</h2>
                                    <button onClick={e => {
                                        document.getElementById('file-input').click();
                                    }} className="uk-button uk-button-default">Upload</button>
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        id="file-input"
                                        {...register("cv", { required: true, validate: files => {
                                            if (files.length == 0) return false;
                                            return true;
                                        } })} disabled={disableForm} />
                                    {errors.cv && errorMessage('Please upload a file')}
                                </div>
                            </div>
                            <div className="w-50 h-100 flex flex-column pv4 ph3">
                                <b>Job Position</b>
                                <input
                                    type="text"
                                    {...register("position", { required: true })}
                                    className="uk-input uk-input-default"
                                    disabled={disableForm}
                                    style={{ marginTop: '10px' }} />
                                {errors.position && requiredMessage}

                                <b className="mt3">Full Name</b>
                                <input
                                    type="text"
                                    {...register("name", { required: true })}
                                    className="uk-input uk-input-default"
                                    disabled={disableForm}
                                    style={{ marginTop: '10px' }} />
                                {errors.name && requiredMessage}

                                <b className="mt3">Email</b>
                                <input
                                    type="email"
                                    {...register("email", { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ })}
                                    className="uk-input uk-input-default"
                                    disabled={disableForm}
                                    style={{ marginTop: '10px' }} />
                                {errors.email && errorMessage('Enter a valid email address')}

                                <b className="mt3">Phone</b>
                                <input
                                    type="text"
                                    {...register("phone", { required: true, pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ })}
                                    className="uk-input uk-input-default"
                                    disabled={disableForm}
                                    style={{ marginTop: '10px' }} />
                                {errors.phone && errorMessage('Enter a valid phone number')}

                                <input
                                    type="submit"
                                    className="uk-button uk-button-primary"
                                    value="Submit Now"
                                    style={{ marginTop: '30px', width: 'auto' }}
                                    disabled={disableForm} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </StandardLayout>
}